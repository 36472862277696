import api from './index';
import { DashboardList, DashboardUrl } from '../types';

const analyticsApi = {
  async getAllDashboards(company: number): Promise<DashboardList | undefined> {
    const response = await api.get(`/company/${company}/dashboards`);
    return response.data as DashboardList;
  },

  async getDashboardById(company: number, id: string): Promise<DashboardUrl | undefined> {
    const response = await api.get(`/company/${company}/dashboards/${id}`);
    return response.data as DashboardUrl;
  },
};

export default analyticsApi;
