import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Dashboard } from './views/Dashboard';
import { QueryClient, QueryClientProvider } from 'react-query';

const App: React.FC = () => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={client}>
      <Router basename="/analytics">
        <Routes>
          <Route index path="/" element={<Dashboard />} />
          <Route path="/:dashboardTitle" element={<Dashboard />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
