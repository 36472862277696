import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { QStack, QTabs, QHeading, QEmptyState, QSpinner, QCenter, QBox } from '@qualio/ui-components';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardContentOptions, DashboardFrame } from 'amazon-quicksight-embedding-sdk/dist/types';
import { useDashboardList, useDashboard } from '../hooks';
import { useParams, useNavigate } from 'react-router-dom';

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const { dashboardTitle } = useParams<{ dashboardTitle: string }>();

  const [embeddedDashboard, setEmbeddedDashboard] = useState<DashboardFrame>();
  const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);

  const { data: dashboardsResponse, isLoading: isDashboardsLoading } = useDashboardList();
  const dashboards = dashboardsResponse?.data?.dashboards;

  let dashboardId = '';
  if (dashboards) {
    if (dashboardTitle) {
      const activeDashboard = dashboards.find((d) => d.title.toLowerCase() === dashboardTitle?.toLowerCase());
      dashboardId = activeDashboard ? activeDashboard.id : dashboards[0].id;
    } else {
      dashboardId = dashboards[0].id;
    }
  }

  const [activeDashboardId, setActiveDashboardId] = useState(dashboardId);

  const contentOptions = useMemo(
    () =>
      ({
        onMessage: async (messageEvent) => {
          switch (messageEvent.eventName) {
            case 'CONTENT_LOADED': {
              setIsDashboardLoaded(true);
              console.log('All visuals are loaded. The title of the document:', messageEvent.message?.title);
              break;
            }
            case 'ERROR_OCCURRED': {
              console.error(
                'Error occurred while rendering the dashboard. Error code:',
                messageEvent.message?.errorCode,
              );
              console.error(messageEvent.message);
              break;
            }
            case 'PARAMETERS_CHANGED': {
              console.log('Parameters changed. Changed parameters:', messageEvent.message?.changedParameters);
              break;
            }
          }
        },
      }) as DashboardContentOptions,
    [],
  );

  const { data: dashboardUrl, isLoading: isDashboardUrlLoading } = useDashboard(dashboardId);

  const createEmbeddedDashboard = useCallback(async () => {
    if (embeddedDashboard) {
      return;
    }

    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;
    const container = document.getElementById('dashboard-container');
    console.log('Found container', container);
    if (dashboardUrl) {
      const frameOptions = {
        url: dashboardUrl.data,
        container: container!,
        scrolling: 'no',
        width: '100%',
        height: '100%',
        resetDisabled: true,
        undoRedoDisabled: true,
        withIframePlaceholder: true,
      };
      const embedded = await embedDashboard(frameOptions, contentOptions);
      setEmbeddedDashboard(embedded);
    }
  }, [contentOptions, dashboardUrl, embeddedDashboard]);

  useEffect(() => {
    createEmbeddedDashboard().catch((e) => console.error(e));
  }, [createEmbeddedDashboard]);

  useEffect(() => {
    if (embeddedDashboard && dashboardId !== activeDashboardId) {
      embeddedDashboard
        .navigateToDashboard(dashboardId, {
          parameters: [],
        })
        .then((navigateResponse) => console.log('Navigation to dashboard completed', navigateResponse));
      setActiveDashboardId(dashboardId);
    }
  }, [embeddedDashboard, activeDashboardId, dashboardId, setActiveDashboardId]);

  return (
    <>
      <QStack direction="column" spacing={6}>
        <QHeading size="lg">Analytics</QHeading>
        {isDashboardsLoading || isDashboardUrlLoading ? (
          <QCenter p={5} mt={10}>
            <QSpinner />
          </QCenter>
        ) : (
          <>
            {!!dashboards ? (
              <QBox pointerEvents={isDashboardLoaded ? 'auto' : 'none'}>
                <QTabs
                  activeTabId={dashboardId}
                  onClick={(tabItem) => navigate(`/${tabItem.title}`)}
                  tabs={dashboards}
                />
              </QBox>
            ) : (
              <QEmptyState title="No Dashboards present" />
            )}
          </>
        )}
      </QStack>
      <QBox id="dashboard-container" height="1900px" mt={6} />
    </>
  );
};
