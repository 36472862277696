import { useQuery } from 'react-query';
import { useCurrentUser, useToastProvider } from '@qualio/ui-components';
import { DashboardList } from '../types';
import analyticsApi from '../api/analytics.api';

type UseDashboardListType = {
  data: DashboardList | undefined;
  isLoading: boolean;
  isSuccess: boolean;
};

export const useDashboardList = (): UseDashboardListType => {
  const { companyId } = useCurrentUser();
  const { showToast } = useToastProvider();
  const { data, isLoading, isSuccess } = useQuery(
    ['analytics-dashboard-list', companyId],
    () => analyticsApi.getAllDashboards(companyId),
    {
      onError: () => {
        showToast({
          description: 'There was a problem communicating with the server.',
          status: 'error',
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isSuccess,
  };
};
