import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './views/ErrorBoundary';
import App from './App';
import { QualioQFrontEndProvider } from '@qualio/ui-components';
import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';

(window as any).renderAnalytics = (containerId: string, user: CurrentUser) => {
  ReactDOM.render(
    <ErrorBoundary>
      <QualioQFrontEndProvider user={user}>
        <App />
      </QualioQFrontEndProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).unmountAnalytics = (containerId: string) => {
  try {
    if (document.getElementById(containerId) !== null) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};
